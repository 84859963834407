import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

import FirebaseAuthService from '../../../firebase/FirebaseAuthService'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    // FirebaseAuthService.logoutUser();
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

// const AuthInit: FC<WithChildren> = ({children}) => {
//   const {auth, logout, setCurrentUser} = useAuth()
//   const didRequest = useRef(false)
//   const [showSplashScreen, setShowSplashScreen] = useState(true)
//   // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
//   useEffect(() => {
//     const requestUser = async (apiToken: string) => {
//       try {
//         if (!didRequest.current) {
//           const {data} = await getUserByToken(apiToken)

//           const currentUser2 =  await FirebaseAuthService.returnState()
//           console.log(currentUser2)

//           if (data) {
//             data.id = currentUser2?.uid!
//             data.first_name = "opor"
//             data.last_name = "Tan"
//             data.email = currentUser2?.email!
//             console.log(data)
//             setCurrentUser(data)
//           }
//         }
//       } catch (error) {
//         console.log(2)

//         console.error(error)
//         if (!didRequest.current) {
//           logout()
//         }
//       } finally {
//         console.log(3)

//         setShowSplashScreen(false)
//       }

//       return () => (didRequest.current = true)
//     }

//     if (auth && auth.api_token) {
//       console.log(4)

//       requestUser(auth.api_token)
//     } else {
//       console.log(5)

//       logout()
//       setShowSplashScreen(false)
//     }
//     // eslint-disable-next-line
//   }, [])

//   return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
// }

export {AuthProvider, useAuth}
