/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {left} from '@popperjs/core'
import {Link} from 'react-router-dom'
import {BsThreeDots} from 'react-icons/bs'
import {FaFolder} from 'react-icons/fa'
import {useState} from 'react'
type Props = {
  icon: string
  title: string
  description: string
  itemId: string
  name: string
  onDelete: (itemId: string, name: string) => void
  onDetail: (itemId: string, name: string) => void
  onShare: (itemId: string, name: string) => void
}

const HomeCard: FC<Props> = ({icon, title, description, itemId, onDelete, name, onDetail, onShare}) => {
  const [folderHovered, setFolderHovered] = useState<boolean>(false)
  const handleDeleteClick = () => {
    // Call the onDelete callback with the itemId
    onDelete(itemId, name)
  }
  const handleDetailClick = () => {
    // Call the onDelete callback with the itemId
    onDetail(itemId, name)
  }

  const handleShareClick = () => {
    // Call the onDelete callback with the itemId
    onShare(itemId, name)
  }

  return (
    <>
      <div className='card h-70'>
        <div
          className='card-header p-0 d-flex justify-content-end align-items-end'
          style={{minHeight: '30px', border: 'none'}}
        >
          <div className='dropdown'>
            <style>
              {`
          .btn-secondary::after {
            display: none;
          }
        `}
            </style>
            <button
              className='btn btn-secondary dropdown-toggle'
              type='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
              style={{background: 'transparent'}}
            >
              <BsThreeDots />
            </button>
            <ul className='dropdown-menu'>
              <li>
                <a className='dropdown-item' href='#' onClick={handleDetailClick}>
                  Info
                </a>
              </li>
              {/* <li>
                <a className='dropdown-item' href='#' onClick={handleShareClick}>
                  Share
                </a>
              </li> */}
              <li>
                <a
                  className='dropdown-item text-red-600 hover:text-red-600'
                  href='#'
                  onClick={handleDeleteClick}
                >
                  Delete
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className='card-body d-flex justify-content-center text-center'
          style={{paddingTop: 0, paddingBottom: 15}}
        >
          <Link
            to={`/file/${itemId}`}
            onMouseEnter={() => setFolderHovered(true)}
            onMouseLeave={() => setFolderHovered(false)}
          >
            <div className='symbol symbol-150px'>
              <FaFolder
                style={{
                  width: '120px',
                  height: 'auto',
                  marginLeft: '3rem',
                  marginRight: '3rem',
                  color: '#4E9BB9',
                  transform: folderHovered ? 'scale(1.2)' : 'scale(1)', // Zoom effect
                  transition: 'transform 0.3s ease', // Smooth transition
                }}
              />
            </div>
            {/* <div className='fs-7 mb-2'>{title}</div> */}

            {/* </div> */}
            {/* </div> */}
            <div
              className=' text-base font-normal mb-2 text-dark'
              style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
            >
              {title.length > 20 ? title.slice(0, 20) + '...' : title}
            </div>
            <div className='fs-7 fw-bold text-gray-400'>{description}</div>
          </Link>
        </div>
      </div>
    </>
  )
}

export {HomeCard}
