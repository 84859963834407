/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import Term from './core/Term'

const AuthLayout = () => {
  const [modalShow, setModalShow] = useState(false)
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  const closeModal = () => {
    setModalShow(false)
  }
  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            {/* <a href='#' className='px-5' target='_blank'> */}
            <span 
            onClick={() => setModalShow(true)}
            style={{cursor:'pointer'}}
            >
              Terms</span>{/* </a> */}
            <a href='https://www.dygistech.com/contactus' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/login_bg.jpg')})` }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/' className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/mLoggerIcon.svg')}
              className='h-75px'
            />
          </Link>
          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className='mx-auto w-275px w-md-50 w-xl-400px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/login_pic.png')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          {/* <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Fast, Efficient and Productive
          </h1> */}
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-white fs-base'>
            An application for automatically backing up files, that user can set Template, <br />
            File type, Creation time by self and ready to download files any time.
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
      {modalShow && <Term modalShow={modalShow} closeModal = {closeModal} />}
    </div>
  )
}

export { AuthLayout }
