import React from 'react'

function FormatSize(sizeInBytes) {
  // console.log(sizeInBytes);
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  if (sizeInBytes != undefined) {
    let unitIndex = 0
    while (sizeInBytes >= 1024 && unitIndex < units.length - 1) {
      sizeInBytes /= 1024
      unitIndex++
    }
    const roundedSize = sizeInBytes.toFixed(2) // Round to two decimal places
    const unit = units[unitIndex]

    return `${roundedSize} ${unit}`
  } else {
    return '0.00 B';
  }
}

export default FormatSize

