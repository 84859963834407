import React, { useState, useEffect, useContext, createContext } from 'react';
import firebase from '../../../../../src/app/firebase/FirebaseConfig';
import { SidebarFooter } from './SidebarFooter';

const firestore = firebase.firestore();

// Create a context
const storageContext = createContext();

export function useStorage() {
    return useContext(storageContext);
}

export function StorageProvider({ children }) {

    const [totalSize, setTotalSize] = useState(0);
    // const [isPremium, setIspremium] = useState(false);
    const [storageLimit, setStorageLimit] = useState(1000000000) // Size in byte
    const [docSize, setDocSize] = useState(0);
    async function StorageUsage(decryptedUser_id) {
        const documentSizes = {};
        // let totalSize = 0;
        let totalSizeAllDocuments = 0;
        const collectionRef = firestore.collection('document');
        const query = collectionRef.where('owner', '==', decryptedUser_id);

        try {
            // Get the documents that match the query
            const querySnapshot = await query.get();

            // Iterate through each document
            for (const doc of querySnapshot.docs) {
                // Query the "file" subcollection of the current document
                const fileCollectionRef = doc.ref.collection('file');
                const fileQuery = fileCollectionRef.where('size', '!=', '');

                // Get the documents in the "file" subcollection
                const fileQuerySnapshot = await fileQuery.get();

                let totalSize = 0; // Total size for the current document

                // Calculate the total size of files in the subcollection
                fileQuerySnapshot.forEach((fileDoc) => {
                    const fileSize = fileDoc.data().size;
                    const fileSizeNum = parseFloat(fileSize);
                    totalSize += fileSizeNum;
                });

                // Store the total size for this document
                documentSizes[doc.id] = totalSize;
                // Add the current document's size to the totalSizeAllDocuments
                totalSizeAllDocuments += totalSize;
            }

            // Now, documentSizes object contains the total sizes for each document
            setDocSize(documentSizes);

            // console.log(totalSize);
            setTotalSize(totalSizeAllDocuments)
            // const formattedSize = await formatBytes(totalSize)
            // if (isPremium) {
            //     setStorageLimit(10000000000); // 10 GB
            // } else {
            //     setStorageLimit(1000000000); // 1 GB
            // }
            setStorageLimit(10000000000); // 10 GB

        } catch (error) {
            console.error('Error in StorageUsage:', error);
            throw error; // You can handle the error here or propagate it to the caller.
        }
    }
    // console.log(totalSize);
    return (
        <storageContext.Provider value={{ totalSize, StorageUsage, storageLimit, docSize }}>
            {children}
        </storageContext.Provider>
    );
}