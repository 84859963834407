

//Change Version


import React from 'react'

function Version() {
  return (
    <span className='text-gray-800'> [V 1.1.1]</span>
  )
}

export default Version