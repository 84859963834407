import React from 'react'
import { HomeCard } from '../../modules/accounts/components/settings/cards/HomeCard'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import firebase from '../../firebase/FirebaseConfig'
import { auto } from '@popperjs/core'
import { MdViewModule, MdViewList } from "react-icons/md";
import { Card, Row, Col, DropdownButton, Dropdown, Container, Form, Table, Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle, Button } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import { useUser } from '../../modules/newauth/UserContext'
import CryptoJS from "crypto-js";
import { showConfirmation, showAlertsucc } from '../../utils/Swal'
import { MdDeleteForever, MdInfoOutline, MdOutlineClose } from 'react-icons/md'
import { FaFolder } from 'react-icons/fa'
import { useStorage } from '../../../_metronic/layout/components/sidebar/StorageUsage'
import { motion } from 'framer-motion'
import { ClipLoader } from 'react-spinners'
import FormatSize from '../../utils/FormatSize'
// const viewIcon = '/media/svg/files/folder.svg';

const dropdownArrow = `
  .dropdown-toggle::after {
    color: white;
  }
`;

export function Home() {
  // const itemsToRender = Array(20).fill(null)
  const themeMode = localStorage.getItem('kt_theme_mode_value');
  const [documentList, setDocumentList] = useState([])
  const [folderNum, setFolderNum] = useState(0)
  const [viewDropdown, setViewDropdown] = useState(sessionStorage.getItem('viewDropdown') || 'Icon')
  const [searchTerm, setSearchTerm] = useState('') // State to store the search term
  const { user } = useUser()
  const [tableKey, setTableKey] = useState(0)
  const [isLoaded, setIsLoaded] = useState(true)
  const navigate = useNavigate();
  const [folderList, setFolderList] = useState([])
  const { StorageUsage, docSize } = useStorage()
  const [infoModal, setInfoModal] = useState(false);
  const [infoData, setInfoData] = useState(null);
  const [shareModal, setShareModal] = useState(false);
  const [shareFolderTitle, setShareFolderTitle] = useState('');
  // const [selectedContact, setSelectedContact] = useState([]);
  const [contactData, setContactData] = useState(JSON.parse(sessionStorage.getItem("contact")))

  // const [folderSize, setFolderSize] = useState(0);
  // console.log(user);

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const querySnapshot = await firebase.firestore().collection('document')
            .where('owner', '==', decryptedUser_id).get();

          const newDocumentList = [];

          for (const doc of querySnapshot.docs) {
            const subcollectionRef = doc.ref.collection('file');
            const subcollectionQuerySnapshot = await subcollectionRef.get();
            const subcollectionSize = subcollectionQuerySnapshot.size;

            const documentData = {
              id: doc.id,
              name: doc.data().name,
              owner: doc.data().owner,
              parameter: doc.data().parameter,
              parameter_name: 'parameter_name' in doc.data() ? doc.data().parameter_name : '',
              period: doc.data().period,
              start_date: new Date(doc.data().start_timestamp.seconds * 1000).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
              }),
              template: doc.data().template,
              fileCount: subcollectionSize,
            };

            newDocumentList.push(documentData);
          }

          setDocumentList(newDocumentList);
          setFolderNum(newDocumentList.length);
          setIsLoaded(false);
        }
      } catch (error) {
        console.error('Error getting documents:', error);
      }
    };

    fetchData();
  }, [user]);


  // console.log(currentUser)
  // console.log(documentList)

  // Search Handler
  let filteredData
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);


  };

  filteredData = documentList.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  // Search Handler
  // console.log(filteredData);


  const columns = [
    {
      name: 'Folder Name',
      selector: (row) => row.name,
      sortable: true,
      width: '70%',
      cell: (row) => (
        <div>
          <a
            onClick={(e) => {
              e.preventDefault()
              navigate(`/file/${row.id}`)
            }}
            style={{ color: 'inherit' }}
          >
            <span className='flex items-center'><FaFolder style={{ marginRight: 5, fontSize: 30, color: '#4E9BB9' }} /><p className='text-base font-normal'>{row.name}</p></span>
          </a>
        </div>
      ),
    },
    {
      name: 'File',
      selector: (row) => row.fileCount,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <div>
            <button
              // style={{ fontSize: 30, color: 'darkred', cursor: 'pointer', background: 'none', border: 'none' }}
              onClick={() => handleDelete(row.id, row.name)} // Replace with your delete function
            >
              <MdDeleteForever className='transition ease-in-out text-4xl cursor-pointer hover:scale-125 hover:text-red-600' />
            </button>
          </div>
          {/* <div className='text-end'>
            <button
              style={{ fontSize: 30, color: 'inherit', cursor: 'pointer', background: 'none', border: 'none' }}
              onClick={() => handleDetail(row.id, row.name)} // Replace with your delete function
            >
              <MdInfoOutline />
            </button>
          </div> */}
        </>
      ),
    },
    {
      name: 'Info',
      cell: (row) => (
        <>
          {/* <div>
            <button
              style={{ fontSize: 30, color: 'darkred', cursor: 'pointer', background: 'none', border: 'none' }}
              onClick={() => handleDelete(row.id, row.name)} // Replace with your delete function
            >
              <MdDeleteForever />
            </button>
          </div> */}
          <div className='text-end'>
            <button
              // style={{ fontSize: 30, color: 'inherit', cursor: 'pointer', background: 'none', border: 'none' }}
              onClick={() => handleDetail(row.id, row.name)} // Replace with your delete function
            >
              <MdInfoOutline className='transition ease-in-out text-4xl cursor-pointer hover:scale-125' />
            </button>
          </div>
        </>
      ),
    },
  ];
  // // console.log(filteredData);
  //   const folderList = filteredData.map((folder, index) => ({
  //     id: folder.id,
  //     name: folder.name,
  //     fileCount: folder.fileCount,
  //   }));
  // console.log(folderList);


  useEffect(() => {
    setFolderList(filteredData.map((folder, index) => ({
      id: folder.id,
      name: folder.name,
      fileCount: folder.fileCount,
      action: folder.action
    })));
  }, [documentList, searchTerm]);

  // console.log(documentList);
  //Handle View Change
  const handleViewDropdown = (selectedOption) => {
    setViewDropdown(selectedOption)
    sessionStorage.setItem('viewDropdown', selectedOption)
  }

  const handleRowSelected = (row) => {
    console.log(row.id)
    const folderId = row.id

    navigate(`/file/${folderId}`)
  }


  // Table Theme 
  useEffect(() => {
    if (tableKey <= 1) setTableKey(tableKey + 1)
    else if (tableKey >= 1) setTableKey(tableKey - 1)
  }, [themeMode])

  let customStyles = {}
  let searchBoxStyle = {}
  if (themeMode === 'light') {
    createTheme('customtheme', {
      text: {
        primary: 'black',
        secondary: 'white',
      },
      background: {
        default: 'white',
      },
      context: {
        background: '#cb4b16',
        text: '#FFFFFF',
      },
      divider: {
        default: '#D9D9D9',
      },
    })
    customStyles = {
      rows: {
        style: {
          '&:hover': {
            backgroundColor: '#D9D9D9',
            cursor: 'pointer',
          },
        },
      },
      head: {
        style: {
          fontSize: '14px',
          fontWeight: 550,
        },
      },
    }
    searchBoxStyle = {
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid #D9D9D9',
      borderRadius: '5px',
      boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
    }
  } else {
    //Dark theme
    createTheme('customtheme', {
      text: {
        primary: 'white',
        secondary: 'white',
      },
      background: {
        default: 'transparent',
      },
      context: {
        background: '#cb4b16',
        text: '#FFFFFF',
      },
      divider: {
        default: '#4D4D4D',
      },
    })
    customStyles = {
      rows: {
        style: {
          '&:hover': {
            backgroundColor: '#272727',
            cursor: 'pointer',
          },
        },
      },
    }
    searchBoxStyle = {
      backgroundColor: '#272727',
      color: '#FFFFFF',
      border: '1px solid #FFFFFF',
      borderRadius: '5px',
      boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
    }
  }
  // Table Theme 

  const handleDelete = async (itemId, name) => {

    try {

      const deleteConfirm = await showConfirmation('Delete Folder', name);

      if (deleteConfirm) {
        // Access the Firestore instance
        const firestore = firebase.firestore();

        const docRef = firestore.collection('document').doc(itemId);


        // Delete the subcollection first
        await deleteSubcollection(docRef);

        // Delete the document
        await docRef.delete();

        showAlertsucc('Delete success')

        StorageUsage(decryptedUser_id)

        // Update folderList in the state by filtering out the deleted item
        const updatedFolderList = folderList.filter(folder => folder.id !== itemId);
        setFolderList(updatedFolderList);
        console.log(`Document with ID ${itemId} deleted successfully.`);
      }
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const handleDetail = async (itemId) => {

    let originalInfoData = documentList.find(item => item.id === itemId);

    // let docTotalSize = docSize.find(item => item.id === itemId);
    // console.log(docTotalSize);
    let updatedInfoData = { ...originalInfoData };
    updatedInfoData.size = FormatSize(docSize[itemId]);

    switch (originalInfoData.template) {
      case 'single_parameter_csv_vertical':
        updatedInfoData.template = 'Single Parameter in CSV format - Vertical';
        updatedInfoData.parameter = [updatedInfoData.parameter_name];
        break;

      case 'multi_parameter_csv_vertical':
        updatedInfoData.template = 'Multi Parameter in CSV format - Vertical';
        updatedInfoData.parameter = updatedInfoData.parameter_name
        break;

      default:
        updatedInfoData.template = 'Single Parameter in CSV format - Vertical';
        break;
    }

    setInfoData(updatedInfoData);
    setInfoModal(true);
  }
  // console.log(infoData);

  const handleShareClick = async (itemId, name) => {
    setShareFolderTitle({
      id: itemId,
      name: name,
    })
    setShareModal(true);
  }
  // console.log(selectedContact);
  // const shareFolder = () => {
  //   console.log(shareFolderTitle.id);
  //   const updateShareContact = {
  //     share:
  //       [selectedContact]
  //   }
  //   console.log(updateShareContact);
  // }


  // Function to delete a subcollection recursively
  const deleteSubcollection = async (docRef) => {
    const subcollectionQuery = docRef.collection('file');
    const subcollectionSnapshot = await subcollectionQuery.get();

    subcollectionSnapshot.forEach(async (subdoc) => {
      // Delete documents in subcollection
      await subdoc.ref.delete();
      // Recursively delete subcollections in subcollection
      await deleteSubcollection(subdoc.ref);
    });
  };

  return (
    <>
      <motion.div
        initial={{ y: -100 }} // Initial position (off-screen to the left)
        animate={{ y: 0 }}    // Target position (on-screen)
        transition={{ duration: 0.1 }} // Animation duration
      >
        <style>{dropdownArrow}</style>
        <div className='d-flex flex-wrap flex-stack mb-6'>
          <p className='font-bold text-2xl'>
            My Folder
            <span className='fs-6 text-gray-400 fw-bold ' style={{ marginLeft: '1rem' }}>
              {folderNum} {folderNum < 2 ? 'folder' : 'folders'}
            </span>
          </p>

          <div className='d-flex my-2'>
            <Link to={`/home/template`}>
              <button type='button' className='btn text-white bg-mlogger-blue hover:bg-mlogger-blue-hv'>
                + Create
              </button>
            </Link>
          </div>
        </div>
        <Row className='mb-5'>
          <Col xs sm md lg={9} className='d-flex justify-content-start align-items-center'>
            <DropdownButton
              className="custom-dropdown-toggle"
              id="dropdown-basic-button"
              title={<span className='text-white'>View : {viewDropdown}</span>} // Set text color for the title text
              size='sm'
              onSelect={handleViewDropdown} // Use onSelect instead of onChange for DropdownButton
              style={{ backgroundColor: '#4E9BB9', borderRadius: '5px', }}
              variant='transparent'
            >
              <Dropdown.Item eventKey='Icon'>
                {' '}
                {/* Use eventKey to identify which option was selected */}
                <span className='flex flex-wrap ml-2'><MdViewModule style={{ fontSize: 20 }} /> <p className='ml-2'>Icon</p></span>

              </Dropdown.Item>
              <Dropdown.Item eventKey='List'>
                {' '}
                {/* Use eventKey to identify which option was selected */}
                <span className='flex flex-wrap ml-2'><MdViewList style={{ fontSize: 20 }} /><p className='ml-2'>List</p></span>
              </Dropdown.Item>
            </DropdownButton>
          </Col>
          <Col xs sm md lg={3} className='d-flex justify-content-end align-items-center'>
            <Form>
              <Form.Control
                type='search'
                placeholder='Search'
                className='me-2'
                aria-label='Search'
                style={{boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px'}}
                value={searchTerm} // Controlled input value
                onChange={handleSearch}
              />
            </Form>
          </Col>
        </Row>
        {viewDropdown === 'Icon' ? (
          <div>
            <div>
              {isLoaded === true ? (
                <div className='flex justify-center items-center h-max w-auto'>
                  {/* <div className='card'>
                    <div className='card-body d-flex justify-content-center p-10 shadow-sm' style={{ width: '100%' }}>
                      <h1 className='fw-bolder' style={{ fontWeight: 700, fontSize: 25 }}>Loading...</h1>
                    </div>
                  </div> */}
                  <ClipLoader
                    color="#4E9BB9"
                    size={80}
                  />
                </div>
              ) :
                null}
            </div>
            <div>
              {folderList?.length === 0 && isLoaded === false ? (
                <div >
                  <div className='card'>
                    <div className='card-body d-flex justify-content-center p-10 shadow-sm' style={{ width: '100%' }}>
                      There are no records to display.
                    </div>
                  </div>
                </div>
              ) :
                null}
              <Row xs={1} sm={2} xl={6} g={6} className="row g-6 g-xl-9">

                {folderList.map((item, index) => (
                  <div className='col-6 col-sm-6 col-xl-2 d-flex justify-content-center' key={item.id}>
                    <HomeCard
                      icon={''}
                      title={item.name}
                      description={`${item.fileCount} ${item.fileCount < 2 ? 'file' : 'files'}`}
                      itemId={item.id}
                      name={item.name}
                      onDelete={handleDelete}
                      onDetail={handleDetail}
                      onShare={handleShareClick}
                    />
                  </div>
                ))}
              </Row>
            </div>
          </div>
        ) : (
          <div>
            {isLoaded === true ? (
              <div className='flex justify-center items-center h-max w-auto'>
                <ClipLoader
                  color="#4E9BB9"
                  size={80}
                />
              </div>
            ) :
              <div>
                <Card className='shadow-sm p-3 rounded'>
                  <DataTable
                    key={tableKey}
                    theme='customtheme'
                    columns={columns}
                    data={folderList}
                    onRowClicked={handleRowSelected}
                    customStyles={customStyles}
                  // progressPending={isLoaded}
                  />
                </Card>
              </div>
            }
          </div>
        )}
      </motion.div>

      {infoModal && (
        <Modal show={infoModal} onHide={() => setInfoModal(false)}>
          <ModalHeader>
            <ModalTitle>
              Folder Info
            </ModalTitle>
            <MdOutlineClose
              className=' text-2xl hover:cursor-pointer hover:bg-gray-400'
              onClick={() => setInfoModal(false)}
            />
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row className='mb-5'>
                <div className='max-sm:w-full col-3 font-semibold'>Folder Name</div>
                <div className='col-auto'>{infoData.name}</div>
              </Row>
              <Row className='mb-5'>
                <div className='max-sm:w-full col-3 font-semibold'>Template</div>
                <div className='col-auto'>{infoData.template}</div>
              </Row>
              <Row className='mb-5'>
                <div className='max-sm:w-full col-3 font-semibold'>Period</div>
                <div className='col-auto capitalize'>{infoData.period}</div>
              </Row>
              <Row className='mb-5'>
                <div className='max-sm:w-full col-3 font-semibold'>Start Date</div>
                <div className='col-auto'>{infoData.start_date}</div>
              </Row>
              <Row className='mb-5'>
                <div className='max-sm:w-full col-3 font-semibold'>Size</div>
                <div className='col-auto'>{infoData.size}</div>
              </Row>
              <div className='md:flex mb-5'>
                <span className='max-sm:w-full col-3 font-semibold'>Parameter</span>
                <ul>
                  {infoData.parameter.map((param, index) => (
                    <li key={index}><span className='ml-1'>{index + 1}. </span>{param}</li>
                  ))}
                </ul>
              </div>
            </Container>
          </ModalBody>
        </Modal>
      )}

      {/* {shareModal && (
        <Modal show={shareModal} onHide={() => setShareModal(!shareModal)}>
          <ModalHeader>
            <ModalTitle>
              Share {`(`}<span className='font-normal'>{shareFolderTitle.name}</span>{`)`} to
            </ModalTitle>
            <MdOutlineClose
              className=' text-2xl hover:cursor-pointer hover:bg-gray-400'
              onClick={() => setShareModal(false)}
            />
          </ModalHeader>
          <ModalBody>
            <Form>
              {contactData.map((contact, index) => (
                <div key={contact.id} className='flex items-center mb-4'>
                  <Form.Check
                    name={contact.name}
                    type='checkbox'
                    id={contact.id}
                    checked={selectedContact.includes(contact.id)}
                    onChange={() => {
                      if (selectedContact.includes(contact.id)) {
                        setSelectedContact(selectedContact.filter(id => id !== contact.id));
                      } else {
                        setSelectedContact([...selectedContact, contact.id]);
                      }
                    }}
                  />
                  <img src={contact.img} alt={contact.name} className='w-14 h-auto rounded-full mx-4' />
                  <span>{contact.name}</span>
                </div>
              ))}
            </Form>
          </ModalBody>
          <ModalFooter className='p-2'>
            <button type='button' className='btn text-white bg-mlogger-blue hover:bg-mlogger-blue-hv'
              onClick={() => shareFolder()}
            >
              OK
            </button>
          </ModalFooter>
        </Modal>
      )} */}
    </>
  )
}

