import firebase from "./FirebaseConfig"
import axios from "axios";


const firestore = firebase.firestore();
const auth = firebase.auth();

// const registerUser = (email, password) => {
//     return auth.createUserWithEmailAndPassword(email, password);
// };

const loginUser = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
};

const logoutUser = () => {
    return auth.signOut();
};

const sendPasswordResetEmail = (email) => {
    return auth.sendPasswordResetEmail(email);
};

const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider;
    return auth.signInWithPopup(provider);
}

const subscribeToAuthChanges = (handleAuthChange) => {
    auth.onAuthStateChanged((user) => {
        handleAuthChange(user);
    });
};

const returnState = () => {
    return auth.currentUser;
};


const checkThridPartyUID = async (email) => {
    const url = 'https://asia-southeast1-dygistechplatform.cloudfunctions.net/apiApp/auth/getUID/m2CtiBtpHZHrRmtwDtx4/' + email;
    try {
        const response = await axios.get(url);
        // console.log('Data fetched:', response.data);
        return response.data;
    } catch (error) {
        // console.error('Error fetching data:', error);
        return error;
    }
};


const registerUser = async (email, password, firstname, lastname, uid_owner) => {
    auth.createUserWithEmailAndPassword(email, password)
        .then(async (userCredential) => {

            const user = userCredential.user;
            const uid = user.uid;

            const document = {
                email: email,
                firstname: firstname,
                lastname: lastname,
                type: "user",
                user_id: uid,
                image_profile: 'https://firebasestorage.googleapis.com/v0/b/dygistechplatform.appspot.com/o/profile_default%2Fdefault.jpeg?alt=media&token=e31f58b8-e740-43d8-a67a-a66805a5540e',
                uid: uid_owner
            };

            return firestore.collection('user').add(document);

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(error);
        });

};







const FirebaseAuthService = {
    registerUser,
    loginUser,
    logoutUser,
    sendPasswordResetEmail,
    loginWithGoogle,
    subscribeToAuthChanges,
    returnState,
    checkThridPartyUID
};

export default FirebaseAuthService;