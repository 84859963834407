import React from 'react'
import { useUser } from '../../modules/newauth/UserContext'
import CryptoJS from 'crypto-js'
import { useState, useEffect } from 'react';
import firebase from '../../firebase/FirebaseConfig'
import { motion } from 'framer-motion';
import { Card, Row, Col, DropdownButton, Dropdown, Container, Form, Table, Modal, ModalBody, ModalHeader, ModalFooter, ModalTitle, Button } from 'react-bootstrap';
import { MdViewModule, MdViewList } from "react-icons/md";
import { SharedCard } from '../../modules/accounts/components/settings/cards/SharedCard';
import { ClipLoader } from 'react-spinners'
import { MdDeleteForever, MdInfoOutline, MdOutlineClose } from 'react-icons/md';
import DataTable, { createTheme } from 'react-data-table-component';
import { FaFolder, FaShareAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom'
import { showConfirmation, showAlertsucc, showAlertLoading, swalClose } from '../../utils/Swal';

const firestore = firebase.firestore();

const dropdownArrow = `
  .dropdown-toggle::after {
    color: white;
  }
`;

export const SharedFolder = () => {
    const { user } = useUser();
    const themeMode = localStorage.getItem('kt_theme_mode_value');
    const [tableKey, setTableKey] = useState(0);
    const [matchingDocuments, setMatchingDocuments] = useState([]);
    const [infoData, setInfoData] = useState([])
    // const [docLoaded, setDocLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true)
    const [viewDropdownShare, setViewDropdownShare] = useState(sessionStorage.getItem('viewDropdownShare') || 'Icon')
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(CryptoJS.enc.Utf8);
    // const [deleted, setDeleted] = useState(false);
    const [sharedNum, setSharedNum] = useState(0);
    const [infoModal, setInfoModal] = useState('');

    const fetchData = async () => {
        console.log('Fetch Data');
        try {
            const querySnapshot = await firestore.collection('document').where(`share.${decryptedUser_id}`, '!=', null).get();
            const matchingDocs = [];

            if (!querySnapshot.empty) {
                const promises = [];

                for (const doc of querySnapshot.docs) {
                    const data = doc.data();
                    const docId = doc.id;
                    const owner = data.owner;

                    // Fetch owner data
                    const ownerDataRef = firestore.collection('user').where('user_id', '==', owner).get();
                    const ownerDataSnapshot = await ownerDataRef;
                    const ownerDoc = ownerDataSnapshot.docs[0];
                    const ownerData = ownerDoc.data();
                    const img = ownerData.image_profile;
                    const name = ownerData.firstname;
                    const fullName = ownerData.firstname + ' ' + ownerData.lastname

                    // Fetch subcollection data
                    const subcollectionRef = firestore.collection('document').doc(docId).collection('file');
                    const subCollectionSnapshot = await subcollectionRef.get();
                    const subCollectionData = subCollectionSnapshot.docs.map((subDoc) => subDoc.data());
                    const subcollectionCount = subCollectionSnapshot.size;

                    const combinedData = {
                        id: docId,
                        ...data,
                        file: subCollectionData,
                        fileCount: subcollectionCount,
                        ownerImage: img,
                        ownerName: name,
                        ownerFullName: fullName,
                        start_date: new Date(data.start_timestamp.seconds * 1000).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                        }),
                    };

                    matchingDocs.push(combinedData);
                }

            }
            setMatchingDocuments(matchingDocs);
            setSharedNum(matchingDocs.length);
            // setDocLoaded(true);
            setIsLoaded(false);
            // setDeleted(false);
            // console.log(matchingDocs);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // console.log(deleted);

    // useEffect(() => {
    //     if (docLoaded) {
    //         console.log('Docloaded');
    //         setShareFolderList(matchingDocuments);
    //         setIsLoaded(false);
    //     }
    // }, [docLoaded]);


    const handleViewDropdown = (selectedOption) => {
        setViewDropdownShare(selectedOption)
        sessionStorage.setItem('viewDropdownShare', selectedOption)
    }

    const handleDelete = async (doc_id, name) => {
        // Reference to the document in the "document" collection
        const deleteConfirm = await showConfirmation('Delete Shared Folder', name);

        if (deleteConfirm) {
            showAlertLoading('Deleting');
            const documentRef = firestore.collection('document').doc(doc_id);
            // Use the get() method to fetch the document
            documentRef.get()
                .then((docSnapshot) => {
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data();
                        if (data && data.share) {
                            const share = data.share;

                            // Check if the document is shared with the specified user
                            if (share[decryptedUser_id]) {
                                // Delete the user from the "share" field
                                delete share[decryptedUser_id];

                                // Update the document in Firestore to remove the user
                                return documentRef.update({ share });
                            } else {
                                console.log(`Document with ID ${doc_id} does not contain user ID ${decryptedUser_id} in the "share" field.`);
                            }
                        } else {
                            console.log(`The document with ID ${doc_id} does not have a "share" field.`);
                        }
                    } else {
                        console.log(`Document with ID ${doc_id} does not exist.`);
                    }
                })
                .then(async () => {
                    console.log(`User ID ${decryptedUser_id} removed from document with ID ${doc_id}.`);
                    await fetchData();
                    swalClose();
                    showAlertsucc('Delete success');
                })
                .catch((error) => {
                    console.error("Error updating document:", error);
                });
        }
    }

    const handleDetail = async (itemId) => {

        let originalInfoData = matchingDocuments.find(item => item.id === itemId);

        // let docTotalSize = docSize.find(item => item.id === itemId);
        // console.log(docTotalSize);
        // console.log(docSize);
        let updatedInfoData = { ...originalInfoData };
        // updatedInfoData.size = FormatSize(docSize[itemId]);

        switch (originalInfoData.template) {
            case 'single_parameter_csv_vertical':
                updatedInfoData.template = 'Single Parameter in CSV format - Vertical';
                updatedInfoData.parameter = [updatedInfoData.parameter_name];
                break;

            case 'multi_parameter_csv_vertical':
                updatedInfoData.template = 'Multi Parameter in CSV format - Vertical';
                updatedInfoData.parameter = updatedInfoData.parameter_name;
                break;

            default:
                updatedInfoData.template = 'Single Parameter in CSV format - Vertical';
                break;
        }

        setInfoData(updatedInfoData);
        setInfoModal(true);
    }


    const columns = [
        {
            name: 'Folder Name',
            selector: (row) => row.name,
            sortable: true,
            width: '40%',
            cell: (row) => (
                <div>
                    <a
                        onClick={(e) => {
                            e.preventDefault()
                            navigate(`/sharedFile/${row.id}`)
                        }}
                        style={{ color: 'inherit' }}
                    >
                        <span className='flex items-center'><FaFolder style={{ marginRight: 5, fontSize: 30, color: '#4E9BB9' }} /><FaShareAlt style={{
                            position: 'absolute',
                            top: 20,
                            left: 23,
                            zIndex: 2,
                            fontSize: 12,
                            color: '#153c4b',
                        }} /><p className='text-base font-normal'>{row.name}</p></span>
                    </a>
                </div>
            ),
        },
        {
            name: 'Owner',
            width: '30%',
            selector: (row) => row.ownerFullName,
            sortable: true,
            cell: (row) => (
                // <div className='flex items-center'>
                    <a
                        onClick={(e) => {
                            e.preventDefault()
                            navigate(`/sharedFile/${row.id}`)
                        }}
                        className='flex items-center'
                        style={{ color: 'inherit' }}
                    >
                        <img src={row.ownerImage} alt={row.name}
                            className='w-10 h-auto mr-4 rounded-full border' />
                        <span>{row.ownerFullName}</span>
                    </a>
                // </div>
            ),
        },
        {
            name: 'File',
            selector: (row) => row.fileCount,
            sortable: true,
        },

        {
            name: 'Action',
            cell: (row) => (
                <>
                    <div>
                        <button
                            // style={{ fontSize: 30, color: 'darkred', cursor: 'pointer', background: 'none', border: 'none' }}
                            onClick={() => handleDelete(row.id, row.name)} // Replace with your delete function
                        >
                            <MdDeleteForever className='transition ease-in-out text-4xl cursor-pointer hover:scale-125 hover:text-red-600' />
                        </button>
                    </div>
                    {/* <div className='text-end'>
                <button
                  style={{ fontSize: 30, color: 'inherit', cursor: 'pointer', background: 'none', border: 'none' }}
                  onClick={() => handleDetail(row.id, row.name)} // Replace with your delete function
                >
                  <MdInfoOutline />
                </button>
              </div> */}
                </>
            ),
        },
        {
            name: 'Info',
            cell: (row) => (
                <>
                    {/* <div>
                <button
                  style={{ fontSize: 30, color: 'darkred', cursor: 'pointer', background: 'none', border: 'none' }}
                  onClick={() => handleDelete(row.id, row.name)} // Replace with your delete function
                >
                  <MdDeleteForever />
                </button>
              </div> */}
                    <div className='text-end'>
                        <button
                            style={{ fontSize: 30, color: 'inherit', cursor: 'pointer', background: 'none', border: 'none' }}
                            onClick={() => handleDetail(row.id, row.name)} // Replace with your delete function
                        >
                            <MdInfoOutline className='transition ease-in-out text-4xl cursor-pointer hover:scale-125' />
                        </button>
                    </div>
                </>
            ),
        },
    ];

    // Table Theme 
    useEffect(() => {
        if (tableKey <= 1) setTableKey(tableKey + 1)
        else if (tableKey >= 1) setTableKey(tableKey - 1)
    }, [themeMode])

    let customStyles = {}
    let searchBoxStyle = {}
    if (themeMode === 'light') {
        createTheme('customtheme', {
            text: {
                primary: 'black',
                secondary: 'white',
            },
            background: {
                default: 'white',
            },
            context: {
                background: '#cb4b16',
                text: '#FFFFFF',
            },
            divider: {
                default: '#D9D9D9',
            },
        })
        customStyles = {
            rows: {
                style: {
                    '&:hover': {
                        backgroundColor: '#D9D9D9',
                        cursor: 'pointer',
                    },
                },
            },
            head: {
                style: {
                    fontSize: '14px',
                    fontWeight: 550,
                },
            },
        }
        searchBoxStyle = {
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #D9D9D9',
            borderRadius: '5px',
        }
    } else {
        //Dark theme
        createTheme('customtheme', {
            text: {
                primary: 'white',
                secondary: 'white',
            },
            background: {
                default: 'transparent',
            },
            context: {
                background: '#cb4b16',
                text: '#FFFFFF',
            },
            divider: {
                default: '#4D4D4D',
            },
        })
        customStyles = {
            rows: {
                style: {
                    '&:hover': {
                        backgroundColor: '#272727',
                        cursor: 'pointer',
                    },
                },
            },
        }
        searchBoxStyle = {
            backgroundColor: '#272727',
            color: '#FFFFFF',
            border: '1px solid #FFFFFF',
            borderRadius: '5px',
        }
    }
    // Table Theme 
    const handleRowSelected = (row) => {
        console.log(row.id)
        const folderId = row.id

        navigate(`/sharedfile/${folderId}`)
    }

    const lowercaseSearchTerm = searchTerm.toLowerCase();

    const filteredData = matchingDocuments.filter((item) =>
        item.name.toLowerCase().includes(lowercaseSearchTerm)
    );


    // console.log(filteredData);
    // 'filteredData' now contains only the items that match the search term.


    return (
        <>
            <motion.div
                initial={{ y: -100 }} // Initial position (off-screen to the left)
                animate={{ y: 0 }}    // Target position (on-screen)
                transition={{ duration: 0.1 }} // Animation duration
            >
                <style>{dropdownArrow}</style>
                <div className='d-flex flex-wrap flex-stack mb-6'>
                    <p className='font-bold text-2xl'>
                        Shared Folder
                        <span className='fs-6 text-gray-400 fw-bold ' style={{ marginLeft: '1rem' }}>
                            {sharedNum} {sharedNum < 2 ? 'folder' : 'folders'}
                        </span>
                    </p>
                </div>
                <Row className='mb-5'>
                    <Col xs sm md lg={9} className='d-flex justify-content-start align-items-center'>
                        <DropdownButton
                            className="custom-dropdown-toggle"
                            id="dropdown-basic-button"
                            title={
                                <span className='text-white'>View : {viewDropdownShare}</span>}
                            size='sm'
                            onSelect={handleViewDropdown} // Use onSelect instead of onChange for DropdownButton
                            style={{ backgroundColor: '#4E9BB9', borderRadius: '5px', }}
                            variant='transparent'
                        >
                            <Dropdown.Item eventKey='Icon'>
                                {' '}
                                {/* Use eventKey to identify which option was selected */}
                                <span className='flex flex-wrap ml-2'><MdViewModule style={{ fontSize: 20 }} /> <p className='ml-2'>Icon</p></span>

                            </Dropdown.Item>
                            <Dropdown.Item eventKey='List'>
                                {' '}
                                {/* Use eventKey to identify which option was selected */}
                                <span className='flex flex-wrap ml-2'><MdViewList style={{ fontSize: 20 }} /><p className='ml-2'>List</p></span>
                            </Dropdown.Item>
                        </DropdownButton>
                    </Col>
                    <Col xs sm md lg={3} className='d-flex justify-content-end align-items-center'>
                        <Form className='d-flex'>
                            <Form.Control
                                type='search'
                                placeholder='Search'
                                className='me-2'
                                aria-label='Search'
                                style={{boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px'}}
                                value={searchTerm} // Controlled input value
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Form>
                    </Col>
                </Row>
                {viewDropdownShare === 'Icon' ? (
                    <div>
                        <div>
                            {isLoaded === true ? (
                                <div className='flex justify-center items-center h-max w-auto'>
                                    {/* <div className='card'>
                    <div className='card-body d-flex justify-content-center p-10 shadow-sm' style={{ width: '100%' }}>
                      <h1 className='fw-bolder' style={{ fontWeight: 700, fontSize: 25 }}>Loading...</h1>
                    </div>
                  </div> */}
                                    <ClipLoader
                                        color="#4E9BB9"
                                        size={80}
                                    />
                                </div>
                            ) :
                                null}
                        </div>
                        <div>
                            {filteredData?.length === 0 && isLoaded === false ? (
                                <div >
                                    <div className='card'>
                                        <div className='card-body d-flex justify-content-center p-10 shadow-sm' style={{ width: '100%' }}>
                                            There are no records to display.
                                        </div>
                                    </div>
                                </div>
                            ) :
                                null}
                            <Row xs={1} sm={2} xl={6} g={6} className="row g-6 g-xl-9">

                                {filteredData.map((item, index) => (
                                    <div className='col-6 col-sm-6 col-xl-2 d-flex justify-content-center' key={item.id}>
                                        <SharedCard
                                            icon={''}
                                            title={item.name}
                                            description={`${item.fileCount} ${item.fileCount < 2 ? 'file' : 'files'}`}
                                            itemId={item.id}
                                            name={item.name}
                                            onDelete={handleDelete}
                                            onDetail={handleDetail}
                                            ownerImg={item.ownerImage}
                                            ownerName={item.ownerName}
                                        // onShare={handleShareClick}
                                        />
                                    </div>
                                ))}
                            </Row>
                        </div>
                    </div>
                ) : (
                    <div>
                        {isLoaded === true ? (
                            <div className='flex justify-center items-center h-max w-auto'>
                                <ClipLoader
                                    color="#4E9BB9"
                                    size={80}
                                />
                            </div>
                        ) :
                            <div>
                                <Card className='shadow-sm p-3 rounded'>
                                    <DataTable
                                        key={tableKey}
                                        theme='customtheme'
                                        columns={columns}
                                        data={filteredData}
                                        onRowClicked={handleRowSelected}
                                        customStyles={customStyles}
                                    // progressPending={isLoaded}
                                    />
                                </Card>
                            </div>
                        }
                    </div>
                )}
            </motion.div>
            {infoModal && (
                <Modal show={infoModal} onHide={() => setInfoModal(false)}>
                    <ModalHeader>
                        <ModalTitle>
                            Folder Info
                        </ModalTitle>
                        <MdOutlineClose
                            className=' text-2xl hover:cursor-pointer hover:bg-gray-400'
                            onClick={() => setInfoModal(false)}
                        />
                    </ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row className='mb-5'>
                                <div className='max-sm:w-full col-3 font-semibold'>Folder Name</div>
                                <div className='col-auto'>{infoData.name}</div>
                            </Row>
                            <Row className='mb-5'>
                                <div className='max-sm:w-full col-3 font-semibold'>Owner</div>
                                <div className='col-auto'>{infoData.ownerFullName}</div>
                            </Row>
                            <Row className='mb-5'>
                                <div className='max-sm:w-full col-3 font-semibold'>Template</div>
                                <div className='col-auto'>{infoData.template}</div>
                            </Row>
                            <Row className='mb-5'>
                                <div className='max-sm:w-full col-3 font-semibold'>Period</div>
                                <div className='col-auto capitalize'>{infoData.period}</div>
                            </Row>
                            <Row className='mb-5'>
                                <div className='max-sm:w-full col-3 font-semibold'>Start Date</div>
                                <div className='col-auto'>{infoData.start_date}</div>
                            </Row>
                            {/* <Row className='mb-5'>
                                <div className='max-sm:w-full col-3 font-semibold'>Size</div>
                                <div className='col-auto'>{infoData.size}</div>
                            </Row> */}
                            <div className='md:flex mb-5'>
                                <span className='max-sm:w-full col-3 font-semibold'>Parameter</span>
                                <ul>
                                    {infoData.parameter.map((param, index) => (
                                        <li key={index}><span className='ml-1'>{index + 1}. </span>{param}</li>
                                    ))}
                                </ul>
                            </div>
                        </Container>
                    </ModalBody>
                </Modal>
            )}
        </>
    )
}
