
import Swal from 'sweetalert2';

const confirmBtnStyle = '#0d6efd';
const cancelBtnStyle = '#dc3545';

//show success alert and navigate to desire page with function only
export const showAlertsuccNav = (message, navigate) => {
    Swal.fire({
        title: message,
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: confirmBtnStyle,
    }).then(() => {
        // Call the navigation function
        navigate();
    });
};

//show failed alert and navigate to desire page with function only
export const showAlertFailedNav = (message, navigate) => {
    Swal.fire({
        title: message,
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: confirmBtnStyle,
    }).then(() => {
        // Call the navigation function
        navigate();
    });
};

//show failed alert with title message
export const showAlertfail = (message) => {
    Swal.fire({
        title: message,
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: confirmBtnStyle,
    });
};

//show success alert with title message
export const showAlertsucc = (message) => {
    Swal.fire({
        title: message,
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: confirmBtnStyle,
    })
};

//show loading alert with title message
export const showAlertLoading = (message) => {
    Swal.fire({
        title: message,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
        }
    });
}

//close alert loading, etc
export const swalClose = () => {
    Swal.close();
}

//show confirmation alert with title and message then return boolean
export const showConfirmation = async (title, message) => {
    const result = await Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: confirmBtnStyle,
        cancelButtonColor: cancelBtnStyle,
        //   reverseButtons: true,
    });

    return result.isConfirmed;
};