/* eslint-disable react/jsx-no-target-blank */
import {KTIcon} from '../../../helpers'
import ProgressBar from 'react-bootstrap/ProgressBar'
import {useEffect, useState} from 'react'
import {useStorage} from './StorageUsage'
import {useUser} from '../../../../app/modules/newauth/UserContext'
import CryptoJS from 'crypto-js'
import FormatSize from '../../../../app/utils/FormatSize'

const SidebarFooter = () => {
  const [size, setSize] = useState<any>('') // Initialize size as an empty string
  // const usage = 100000
  const [sizeLimit, setSizeLimit] = useState<number>(10)
  const [progress, setProgress] = useState<any>()
  const [percent, setPercent] = useState<any>()
  const {totalSize, StorageUsage} = useStorage()
  const {user} = useUser()

  const decryptedUser_id = CryptoJS.AES.decrypt(user.user_id, 'encryptionSecretKey').toString(
    CryptoJS.enc.Utf8
  )

  // console.log(totalSize)

  useEffect(() => {
    StorageUsage(decryptedUser_id)
  }, [])

  useEffect(() => {
    const formattedSize: string = FormatSize(totalSize)
    const progressBar = (totalSize / (sizeLimit * 1000000000)) * 100
    // console.log(progressBar);
    const percent = progressBar.toFixed(2)
    setProgress(progressBar)
    setPercent(percent)
    setSize(formattedSize)
  }, [totalSize])

  const progressBarStyle = `
  .progress-bar {
    background-color:#4E9BB9
  }
`
  return (
    <>
      <div
        className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6'
        id='kt_app_sidebar_footer'
      >
        <style>{progressBarStyle}</style>
        <ProgressBar style={{backgroundColor: 'white'}} className='mb-3' now={progress} />
        <div className='text-light d-flex justify-content-between overflow-hidden text-nowrap px-0 h-40px w-100'>
          <span style={{fontSize: 12, color: 'white'}}>Usage {size} from {sizeLimit} GB</span>
          <span style={{fontSize: 12, color: 'white'}}>{percent}%</span>
        </div>
        <div className='text-light'></div>
      </div>
    </>
  )
}

export {SidebarFooter}
