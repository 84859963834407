import React from 'react'
// import { KTIcon } from '../../../_metronic/helpers'
// import { Card4 } from '../../../_metronic/partials/content/cards/Card4'
// import { Link } from 'react-router-dom'
import { MdDeleteForever, MdBackup, MdDownload } from 'react-icons/md'
import { useEffect, useState } from 'react'
import { MoonLoader } from 'react-spinners'
import DataTable, { createTheme } from 'react-data-table-component';
import { useParams, Link } from 'react-router-dom'
import firebase from '../../firebase/FirebaseConfig'
import axios from 'axios'
import { useUser } from '../../modules/newauth/UserContext'
import { useStorage } from '../../../_metronic/layout/components/sidebar/StorageUsage';
import CryptoJS from 'crypto-js';
import { showAlertfail } from '../../utils/Swal';
import { motion } from 'framer-motion';

export function SharedFile() {
    const [documentList, setDocumentList] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [folderName, setFolderName] = useState('My Folder');
    const [fileList, setFileList] = useState({});
    const [tableKey, setTableKey] = useState(0);
    const themeMode = localStorage.getItem('kt_theme_mode_value');
    const [searchQuery, setSearchQuery] = useState('');
    const { id } = useParams()
    const { user } = useUser();
    const { StorageUsage, totalSize, storageLimit } = useStorage();
    const [permit, setPermit] = useState('');


    const decryptedUser_id = CryptoJS.AES.decrypt(
        user.user_id,
        "encryptionSecretKey"
    ).toString(CryptoJS.enc.Utf8);

    useEffect(() => {

        firebase
            .firestore()
            .collection('document')
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    setFolderName(data.name);
                    const permit = data.share[decryptedUser_id].permission;
                    setPermit(permit);
                }
                // Subscribe to changes in the 'share' field for Permit updates
                const permitUnsubscribe = firebase
                    .firestore()
                    .collection('document')
                    .doc(id)
                    .onSnapshot((snapshot) => {
                        const updatedPermit = snapshot.data()?.share[decryptedUser_id]?.permission;
                        if (updatedPermit !== undefined) {
                            setPermit(updatedPermit);
                        }
                    });

                const unsubscribeSnapshot = firebase
                    .firestore()
                    .collection('document')
                    .doc(id)
                    .collection('file')
                    .orderBy('start_timestamp', 'asc')
                    .onSnapshot(
                        async (querySnapshot) => {
                            const newDocumentList = []

                            for (const doc of querySnapshot.docs) {
                                const docData = doc.data()
                                const startTimestampFormatted = await dateTimeFormat(docData.start_timestamp.seconds)
                                const stopTimestampFormatted = await dateTimeFormat(docData.stop_timestamp.seconds)

                                let size = ''
                                if (docData.size !== '') {
                                    size = await formatBytes(docData.size)
                                }

                                const documentData = {
                                    id: doc.id,
                                    name: docData.name,
                                    size: size,
                                    start_timestamp_formatted: startTimestampFormatted,
                                    stop_timestamp_formatted: stopTimestampFormatted,
                                    start_timestamp: docData.start_timestamp.seconds,
                                    stop_timestamp: docData.stop_timestamp.seconds,
                                    status: docData.status,
                                    type: docData.type,
                                }
                                newDocumentList.push(documentData)
                            }

                            setDocumentList(newDocumentList)
                            setDataLoaded(true)
                        },
                        (error) => {
                            console.log('Error getting documents: ', error)
                        }
                    )

                return () => {
                    unsubscribeSnapshot();
                    permitUnsubscribe();
                }
            })
            .catch((error) => {
                console.log('Error getting document:', error)
            })
    }, [])
    // console.log(documentList);

    useEffect(() => {
        if (dataLoaded) {
            processData(id, documentList)
            setFileList(documentList.map((file, index) => ({
                id: file.id,
                date: `${file.start_timestamp_formatted} - ${file.stop_timestamp_formatted}`,
                name: file.name,
                size: file.size,
                status: ItemStatusHandle(file.status),

            }))
            );
        }
    }, [dataLoaded])

    useEffect(() => {

        const updatedFileList = documentList.map((file, index) => ({
            id: file.id,
            date: `${file.start_timestamp_formatted} - ${file.stop_timestamp_formatted}`,
            name: file.name,
            size: file.size,
            status: ItemStatusHandle(file.status),
        }));
        setFileList(updatedFileList);

    }, [documentList]);


    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length > 0) {
            const filteredData = fileList.filter((item) =>
                item.date.toLowerCase().includes(query.toLowerCase())
            );
            // console.log(filteredData);
            setFileList(filteredData)
        } else {
            const updatedFileList = documentList.map((file, index) => ({
                id: file.id,
                date: `${file.start_timestamp_formatted} - ${file.stop_timestamp_formatted}`,
                name: file.name,
                size: file.size,
                status: ItemStatusHandle(file.status),
            }));
            setFileList(updatedFileList);
        }
    }

    // console.log(documentList);

    async function handleBackupClick(fileId) {
        // console.log(totalSize);
        if (totalSize < storageLimit) {
            const postData = { document_id: id, file_id: fileId }

            firebase
                .firestore()
                .collection('document')
                .doc(id)
                .collection('file')
                .doc(fileId)
                .update({
                    status: 'processing',
                })
                .then(async () => {
                    await axios
                        .post(
                            'https://asia-southeast1-malin1-app-mlogger.cloudfunctions.net/createFileCSV/create',
                            postData
                        )

                        ///////////////////local test/////////////////
                        // await axios
                        //   .post(
                        //     'http://127.0.0.1:5001/malin1-app-mlogger/asia-southeast1/createFileCSV/create',
                        //     postData
                        //   )

                        .then(async (response) => {
                            await StorageUsage(decryptedUser_id)
                            console.log('Response:', response.data)
                        })
                        .catch((error) => {
                            console.error('Error:', error)
                            firebase
                                .firestore()
                                .collection('document')
                                .doc(id)
                                .collection('file')
                                .doc(fileId)
                                .update({
                                    status: 'non_backup',
                                })
                                .then(() => {
                                    console.log('Document successfully updated!')
                                })
                                .catch((error) => {
                                    // The document probably doesn't exist.
                                    console.error('Error updating document: ', error)
                                })
                        })
                    console.log('Document successfully updated!')
                })
                .catch((error) => {
                    // The document probably doesn't exist.
                    console.error('Error updating document: ', error)
                })
        } else {
            showAlertfail('Your storage is full')
            console.log('Your storage is full');
        }
    }

    async function handleDeleteClick(fileId) {

        setFileList((documentList) =>
            documentList.map((row) =>
                row.id === fileId ? { ...row, status: 'Processing' } : row
            )
        );
        await firebase
            .firestore()
            .collection('document')
            .doc(id)
            .collection('file')
            .doc(fileId)
            .update({
                status: 'processing',
            })
            .then(() => {
                console.log('Document successfully updated!')
            })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error('Error updating document: ', error)
            })

        var desertRef = firebase.storage().ref()
        await desertRef
            .child('/document/' + id + '/' + fileId + '.csv')
            .delete()
            .then(async () => {
                console.log('File deleted successfully')
                await firebase
                    .firestore()
                    .collection('document')
                    .doc(id)
                    .collection('file')
                    .doc(fileId)
                    .update({
                        status: 'non_backup',
                        link: '',
                        metadata: {},
                        size: '',
                    })
                    .then(async () => {
                        console.log('Document successfully updated!')

                        await StorageUsage(decryptedUser_id)
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error('Error updating document: ', error)
                    })
            })
            .catch(async (error) => {
                console.log(error)
                await firebase
                    .firestore()
                    .collection('document')
                    .doc(id)
                    .collection('file')
                    .doc(fileId)
                    .update({
                        status: 'non_backup',
                        link: '',
                        metadata: {},
                        size: '',
                    })
                    .then(() => {
                        console.log('Document successfully updated!')
                    })
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error('Error updating document: ', error)
                    })
            })
    }

    async function handleDownloadClick(fileId) {
        var docRef = firebase.firestore().collection('document').doc(id).collection('file').doc(fileId)
        let filename = ''

        await docRef
            .get()
            .then((doc) => {
                if (doc.exists) {
                    filename = doc.data().name
                } else {
                    console.log('No such document!')
                }
            })
            .catch((error) => {
                console.log('Error getting document:', error)
            })

        var storageRef = firebase.storage().ref()
        await storageRef
            .child('/document/' + id + '/' + fileId + '.csv')
            .getDownloadURL()
            .then((url) => {
                console.log(url)

                function downloadFile(url, filename) {
                    var xhr = new XMLHttpRequest()
                    xhr.open('GET', url, true)
                    xhr.responseType = 'blob'

                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            var blob = xhr.response
                            var link = document.createElement('a')
                            link.href = window.URL.createObjectURL(blob)
                            link.download = filename
                            link.click()
                            window.URL.revokeObjectURL(link.href)
                        }
                    }
                    xhr.send()
                }

                downloadFile(url, filename)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const ItemStatusHandle = (status) => {
        switch (status) {
            case 'backup':
                return 'Backup'
            case 'non_backup':
                return 'None Backup'
            case 'processing':
                return 'Processing'
            default:
                return 'No status'
        }
    }

    const columns = [
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Size',
            selector: row => row.size,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => row.action,
            cell: (row) => (
                <>
                    {row.status === 'Backup' && permit.download ? (
                        <div>
                            <button
                                // style={{ fontSize: 30}}
                                onClick={() => { handleDownloadClick(row.id) }} // Replace with your delete function
                            >
                                <MdDownload className='transition ease-in-out text-4xl cursor-pointer hover:scale-125 hover:text-green-600' />
                            </button>
                            {/* <button
                                style={{ fontSize: 30, color: 'darkred', cursor: 'pointer', background: 'none', border: 'none' }}
                                onClick={() => { handleDeleteClick(row.id) }} // Replace with your delete function
                            >
                                <MdDeleteForever />
                            </button> */}
                        </div>
                    ) : ('')}
                    {row.status === 'None Backup' && permit.upload ? (
                        <div>
                            <button
                                // style={{ fontSize: 30, cursor: 'pointer', background: 'none', border: 'none', color: '#4E9BB9' }}
                                onClick={() => {
                                    handleBackupClick(row.id);
                                    row.status = 'Processing';
                                }}
                            >
                                <MdBackup className='transition ease-in-out text-4xl cursor-pointer hover:scale-125 hover:text-mlogger-blue' />
                                {/* <MoonLoader size={22} color={'#4E9BB9'} loading={true} /> */}
                            </button>
                        </div>
                    ) : ('')}
                    {row.status === 'Processing' ? (
                        <div>
                            <MoonLoader size={22} color={themeMode === 'light' ? ('black') : ('white')} loading={true} />
                        </div>
                    ) : ('')}
                </>
            ),
        },
    ];

    // Table Theme 
    useEffect(() => {
        if (tableKey <= 1) setTableKey(tableKey + 1)
        else if (tableKey >= 1) setTableKey(tableKey - 1)
    }, [themeMode])

    let customStyles = {}
    if (themeMode === 'light') {
        createTheme('customtheme', {
            text: {
                primary: 'black',
                secondary: 'white',
            },
            background: {
                default: 'white',
            },
            context: {
                background: '#cb4b16',
                text: '#FFFFFF',
            },
            divider: {
                default: '#D9D9D9',
            },
        })
        customStyles = {
            rows: {
                style: {
                    '&:hover': {
                        backgroundColor: '#D9D9D9',
                    },
                },
            },
            head: {
                style: {
                    fontSize: '14px',
                    fontWeight: 550,
                },
            },
        }
    } else {
        //Dark theme
        createTheme('customtheme', {
            text: {
                primary: 'white',
                secondary: 'white',
            },
            background: {
                default: 'transparent',
            },
            context: {
                background: '#cb4b16',
                text: '#FFFFFF',
            },
            divider: {
                default: '#4D4D4D',
            },
        })
        customStyles = {
            rows: {
                style: {
                    '&:hover': {
                        backgroundColor: '#272727',
                    },
                },
            },
        }
    }
    // Table Theme 

    return (
        <>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ duration: 0.2 }}
            >
                <div className='flex flex-wrap flex-stack mb-6'>
                    <p className='text-2xl font-bold'>
                        {folderName}
                        {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>100+ resources</span> */}
                    </p>
                    <div className='d-flex my-2'>
                        <div>
                            <div className='flex justify-end mb-8'>
                                <p>
                                    <Link to={`/sharedFolder`}>
                                        Shared Folder
                                    </Link>
                                    {' > '}
                                    {folderName}
                                </p>
                            </div>
                            <div className="container mt-4">
                                <form>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            value={searchQuery}
                                            style={{ boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px' }}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------------- */}
                <div className={`card`}>
                    <div className='card-body pb-2'>
                        {/* <div className='table-responsive'> */}
                        {/* <table className='table table-striped gy-7 gs-7'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Size</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {documentList.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      {item.start_timestamp_formatted} -- {item.stop_timestamp_formatted}
                    </td>
                    <td>{item.name}</td>
                    <td>{item.size}</td>
                    <td>{ItemStatusHandle(item.status)}</td>
                    <td>
                      {item.status === 'non_backup' ? (
                        <span style={{ marginRight: '10px' }}>
                          <i onClick={() => handleBackupClick(item.id)} style={{ cursor: 'pointer' }}>
                            <MdBackup style={{ fontSize: 30 }} />
                          </i>
                        </span>
                      ) : (
                        ''
                      )}
                      {item.status === 'backup' ? (
                        <span style={{ marginRight: '10px' }}>
                          <i
                            onClick={() => handleDownloadClick(item.id)}
                            style={{ cursor: 'pointer' }}
                          >
                            <MdDownload style={{ fontSize: 30 }} />
                          </i>
                        </span>
                      ) : (
                        ''
                      )}

                      {item.status === 'backup' ? (
                        <span style={{ marginRight: '10px' }}>
                          <i onClick={() => handleDeleteClick(item.id)} style={{ cursor: 'pointer' }}>
                            <MdDeleteForever style={{ fontSize: 30, color: 'darkred' }} />
                          </i>
                        </span>
                      ) : (
                        ''
                      )}
                      {item.status === 'processing' ? (
                        <MoonLoader size={22} color={'#4E9BB9'} loading={true} />
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
                        <DataTable
                            key={tableKey}
                            theme='customtheme'
                            columns={columns}
                            data={fileList}
                            customStyles={customStyles}
                        />
                        {/* </div> */}
                    </div>
                </div>
                {/* <StorageUsage
        storageUpdate={storageUpdate} /> */}
                {/* <SidebarFooter totalSize={totalSize} /> */}
            </motion.div>
        </>
    )
}

async function dateTimeFormat(timestamp) {
    var date = new Date(timestamp * 1000) // Convert to milliseconds

    var day = date.getDate().toString().padStart(2, '0')
    var month = (date.getMonth() + 1).toString().padStart(2, '0')
    var year = date.getFullYear().toString().substr(-2)

    var formattedDate = `${day}/${month}/${year}`

    return formattedDate
}

async function dateTimeFormatMMYY(timestamp) {
    var date = new Date(timestamp * 1000)
    var month = (date.getMonth() + 1).toString().padStart(2, '0') // Adding 1 because months are zero-based
    var year = date.getFullYear().toString().slice(2)

    return month + '-' + year
}

async function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + units[i]
}

async function processData(id, documentList) {
    console.log(documentList)

    try {
        // Fetch the document data from Firestore
        const docSnapshot = await firebase.firestore().collection('document').doc(id).get()

        if (docSnapshot.exists) {
            const docData = docSnapshot.data()

            let startTimestamp = docData.start_timestamp.seconds
            const startDate = new Date(startTimestamp * 1000) // Assuming the provided timestamps are in milliseconds
            const endDate = new Date()

            const validDates = []

            const currentDate = new Date(startDate)
            while (currentDate <= endDate) {
                const firstSecondOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)

                const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
                const lastSecondOfMonth = new Date(
                    lastDayOfMonth.getFullYear(),
                    lastDayOfMonth.getMonth(),
                    lastDayOfMonth.getDate(),
                    23,
                    59,
                    59
                )

                if (firstSecondOfMonth >= startDate && lastSecondOfMonth <= endDate) {
                    validDates.push({
                        firstSecond: firstSecondOfMonth,
                        firstSecond_time: new Date(firstSecondOfMonth).getTime() / 1000,
                        lastSecond: lastSecondOfMonth,
                        lastSecond_time: new Date(lastSecondOfMonth).getTime() / 1000,
                    })
                }

                currentDate.setMonth(currentDate.getMonth() + 1)
            }

            console.log(validDates)

            // Iterate over validDates and perform your operations
            for (const item of validDates) {
                // Check if the item's start_timestamp exists in documentList
                var idExists = documentList.some(function (obj) {
                    return obj.start_timestamp === item.firstSecond_time
                })

                if (idExists) {
                    console.log('ID exists in the array.')
                } else {
                    console.log('ID does not exist in the array.')
                    let name = `${docData.name} (${await dateTimeFormatMMYY(item.firstSecond_time)}).csv`

                    const document = {
                        name: name,
                        size: '',
                        start_timestamp: item.firstSecond,
                        stop_timestamp: item.lastSecond,
                        status: 'non_backup',
                        template: docData.template,
                        type: 'csv',
                    }

                    // Save the document in Firestore
                    await firebase.firestore().collection('document').doc(id).collection('file').add(document)

                }

                console.log('------------------')
            }
        } else {
            console.log('No such document!')
        }
    } catch (error) {
        console.log('Error:', error)
    }
}

